import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { green, orange, red } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

const CustomModal = ({ isOpen, toggle, message }) => {
  const renderModalIcon = () => {
    switch (message.type) {
      case "success":
        return <CheckCircleIcon sx={{ color: green[500], fontSize: '2em' }} />;
      case "warning":
        return <WarningIcon sx={{ color: orange[500], fontSize: '2em' }} />;
      case "error":
        return <ErrorIcon sx={{ color: red[500], fontSize: '2em' }} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center' }}>
          {renderModalIcon()} Mensaje
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {message.text}
        </Typography>
        <Button onClick={toggle} variant="contained" sx={{ mt: 2, backgroundColor: '#5a67d8', color: 'white', border: 'none' }}>
          Cerrar
        </Button>
      </Box>
    </Modal>
  );
};

export default CustomModal;