import React, { useState, useMemo ,useEffect} from 'react';
import {Paper,Container,Grid} from "@mui/material";
import GraficoIngresos from './Dasboards/GraficoIngresos';
import GraficoIngresosHab from './Dasboards/GraficoIngresosHab';

const Dashboard = () => {
  useEffect(() => {
    document.title = "Dashboard";
  }, []);
  return (
  
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',      
          }}
        >
        <GraficoIngresos/>
        </Paper>
      </Grid>
  

      <Grid item xs={12} md={4} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
           
          }}
        >
        <GraficoIngresosHab/>
        </Paper>
      </Grid>
      

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
         {/*OTRO GRAFICOS*/}
        </Paper>
      </Grid>

    </Grid>

  </Container>
    
  );
};




export default Dashboard;
