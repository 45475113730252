import React, { useState, useEffect } from "react";
import { Grid,Button, Typography,TextField, Table, 
        TableHead,TableRow,TableCell,TableBody,TableFooter,Paper,TableContainer} from '@mui/material';
import { url } from "./Config";
import CustomModal from "./CustomModal";

function IngresosDiario() {
  useEffect(() => {
    document.title = "Cierre Diario";
  }, []);

  const [userData, setUserData] = useState([]);
  const [modalMessage, setModalMessage] = useState({ type: "", text: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fechaDesde, setFechaDesde] = useState("");
  const [fechaHasta, setFechaHasta] = useState("");
  const [ide_eje, set_ide_eje] = useState('');

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      if (parsedUserData.length > 0) {
        set_ide_eje(parsedUserData[0].ide_eje);
      }
  }
    const today = new Date();
    const localToday = today.toLocaleDateString('en-CA');
    setFechaDesde(localToday);
    setFechaHasta(localToday);
  }, []);

  useEffect(() => {
      if (ide_eje) {
        ConsultaCierreDiario(fechaDesde, fechaHasta);
      }
  }, [ide_eje]);

  const ConsultaCierreDiario = async (desde, hasta) => {      
      try {
        const requestData = {ide_eje: ide_eje,desde: desde,hasta: hasta,};
        const response = await fetch(`${url}/post_vw_ingresos_cab`, {
          method: "POST",
          referrerPolicy: "unsafe-url" ,
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestData),
        });

        const data = await response.json();
        if (data.success && data.data) {
          setUserData(data.data);
        } else {
          setUserData([]);
          setModalMessage({ type: "warning", text: "No se encontraron datos." });
          setIsModalOpen(true);
        }
      } catch (error) {
        setUserData([]);
        setModalMessage({ type: "error", text: "Error fetching user data: " + error.message });
        setIsModalOpen(true);
      }
  };

  const ClickConsultaCierreDiario = () => {
    ConsultaCierreDiario(fechaDesde, fechaHasta);
  };
  const toggleModal = () => {
      setIsModalOpen(!isModalOpen);
  };
  const FontSize = "12px";
  const TotalEfectivo   = userData.reduce((acc, item) => acc + Number(item.ing_efe), 0);
  const TotalDeposito   = userData.reduce((acc, item) => acc + Number(item.ing_dep), 0);
  const TotalGastos     = userData.reduce((acc, item) => acc + Number(item.gas_tos), 0);
  const Total           = userData.reduce((acc, item) => acc + Number(item.val_tot), 0);
  return (
  <>
    <Typography fullWidth variant="h4" textAlign="left" color="textPrimary" mb={1}>
    Cierre Diario de Ingresos
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={2}>
        <TextField
            id="fechaDesde"
            type="date"
            value={fechaDesde}
            onChange={(e) => setFechaDesde(e.target.value)}
            fullWidth
            label = "Desde"
            size="small"           
        />
      </Grid>
      <Grid item xs={12} sm={2}>           
        <TextField
            id="fechaHasta"
            type="date"
            value={fechaHasta}
            onChange={(e) => setFechaHasta(e.target.value)}
            fullWidth
            label = "Hasta"
            size="small"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Button  fullWidth variant="contained" onClick={ClickConsultaCierreDiario}>
        Consultar
        </Button>
      </Grid>
    </Grid> 
    <TableContainer component={Paper} sx={{ maxHeight: "70vh", marginTop: 2, overflowX: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ textAlign: 'Left', width: '0%', fontSize: FontSize }}>#</TableCell>
                    <TableCell sx={{ textAlign: 'center', width: '2%', fontSize: FontSize }}>N° Cierre</TableCell>
                    <TableCell sx={{ textAlign: 'center', width: '7%', fontSize: FontSize }}>Fecha Cierre</TableCell>
                    <TableCell sx={{ textAlign: 'center', width: '15%', fontSize: FontSize }}>Trabajador</TableCell>
                    <TableCell sx={{ textAlign: 'center', width: '15%', fontSize: FontSize }}>Observación</TableCell>            
                    <TableCell sx={{ textAlign: 'center', width: '5%', fontSize: FontSize }}>Efectivo</TableCell>
                    <TableCell sx={{ textAlign: 'center', width: '2%', fontSize: FontSize }}>Deposito</TableCell>
                    <TableCell sx={{ textAlign: 'center', width: '2%', fontSize: FontSize }}>Gastos</TableCell>
                    <TableCell sx={{ textAlign: 'center', width: '2%', fontSize: FontSize }}>Total</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {userData.map((row, index) => (
                <TableRow key={index}>
                   <TableCell sx={{ fontSize: FontSize }}>{index + 1}</TableCell>
                    <TableCell sx={{ fontSize: FontSize }}>{row.nro_i_c}</TableCell>
                    <TableCell sx={{ fontSize: FontSize }}>{row.fch_i_c_txt}</TableCell>
                    <TableCell sx={{ fontSize: FontSize }}>{row.nom_trb}</TableCell>
                    <TableCell sx={{ fontSize: FontSize }}>{row.obs_i_c}</TableCell>  
                    <TableCell sx={{ fontSize: FontSize, textAlign: 'right', minWidth: 100 }}>{Number(row.ing_efe).toFixed(2)}</TableCell>
                    <TableCell sx={{ fontSize: FontSize, textAlign: 'right', minWidth: 100 }}>{Number(row.ing_dep).toFixed(2)}</TableCell>
                    <TableCell sx={{ fontSize: FontSize, textAlign: 'right', minWidth: 100 }}>{Number(row.gas_tos).toFixed(2)}</TableCell>
                    <TableCell sx={{ fontSize: FontSize, textAlign: 'right', minWidth: 100 }}>{Number(row.val_tot).toFixed(2)}</TableCell>
                </TableRow>
            ))}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TableCell sx={{ fontSize: "15px", fontFamily: 'Arial', fontWeight: "bold" }}>Total</TableCell>
                    <TableCell colSpan={4} />
                    <TableCell sx={{ fontSize: "15px", fontFamily: 'Arial', fontWeight: "bold", textAlign: 'right' }}>{TotalEfectivo.toFixed(2)}</TableCell>
                    <TableCell sx={{ fontSize: "15px", fontFamily: 'Arial', fontWeight: "bold", textAlign: 'right' }}>{TotalDeposito.toFixed(2)}</TableCell>
                    <TableCell sx={{ fontSize: "15px", fontFamily: 'Arial', fontWeight: "bold", textAlign: 'right' }}>{TotalGastos.toFixed(2)}</TableCell>
                    <TableCell sx={{ fontSize: "15px", fontFamily: 'Arial', fontWeight: "bold", textAlign: 'right' }}>{Total.toFixed(2)}</TableCell>
                </TableRow>
            </TableFooter>
        </Table>
        </TableContainer>
        <CustomModal isOpen={isModalOpen} toggle={toggleModal} message={modalMessage} />     
  </>
  )
}

export default IngresosDiario
