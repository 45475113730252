import React, { useState, useEffect } from "react";
import { Grid, FormLabel, Input, Button, Typography } from '@mui/material';
import { PieChart  } from '@mui/x-charts/PieChart';
import { url } from "../Config";
import CustomModal from "../CustomModal";

const GraficoIngresos = () => {
    const [userData, setUserData] = useState([]);
    const [modalMessage, setModalMessage] = useState({ type: "", text: "" });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    const [ide_eje, set_ide_eje] = useState('');

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
        const parsedUserData = JSON.parse(storedUserData);
        if (parsedUserData.length > 0) {
            set_ide_eje(parsedUserData[0].ide_eje);
        }
        }
        const today = new Date();
        const localToday = today.toLocaleDateString('en-CA');
        setFechaDesde(localToday);
        setFechaHasta(localToday);
    }, []);
    useEffect(() => {
        if (ide_eje) {
            ConsultaGrafico(fechaDesde, fechaHasta);
        }
    }, [ide_eje]);


    const ConsultaGrafico = async (desde, hasta) => {
        try {
        const requestData = {ide_eje: ide_eje,desde: desde,hasta: hasta,};
        const response = await fetch(`${url}/post_dashboard_ingreso_x_tipo_comprobante`, {
            method: "POST",
            referrerPolicy: "unsafe-url",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestData),
        });

        const data = await response.json();
        if (data.success && data.data) {
            setUserData(data.data);
           
        } else {
            setUserData([]);
            setModalMessage({ type: "warning", text: "No se encontraron datos." });
            setIsModalOpen(true);
        }
        } catch (error) {
        setUserData([]);
        setModalMessage({ type: "error", text: "Error: " + error.message });
        setIsModalOpen(true);
        } 
    };
    const ClickConsultaGrafico = () => {
        ConsultaGrafico(fechaDesde, fechaHasta);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    return (
    <>
    <Typography variant="contained" sx={{ fontWeight: 'bold' }} textAlign="center" mb={1}>
    Grafico de Ingresos por Tipo de Comprobante
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={4}>
        <FormLabel sx={{ fontWeight: 'bold' }}>Desde</FormLabel>
        <Input
          id="fechaDesde"
          type="date"
          value={fechaDesde}
          onChange={(e) => setFechaDesde(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormLabel sx={{ fontWeight: 'bold' }}>Hasta</FormLabel>
        <Input
          id="fechaHasta"
          type="date"
          value={fechaHasta}
          onChange={(e) => setFechaHasta(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button variant="contained" onClick={ClickConsultaGrafico}>
          Consultar
        </Button>
      </Grid>
      <Grid item xs={12}>
      <PieChart
            series={[
                        {
                            data: userData.map(item => ({
                                id          : item,
                                value       : item.imp_tot,
                                label       : item.des_doc,
                                value_txt   : item.imp_tot_txt
                            })),
                            arcLabel: (item) => `S/.${item.value_txt}`,
                            arcLabelMinAngle: 45,
                        },
                    ]}
                    height={200}
                    
        />
      </Grid>
    </Grid>
    {/*<CustomModal isOpen={isModalOpen} toggle={toggleModal} message={modalMessage} />*/}
    </>
    
    );
};

export default GraficoIngresos;
