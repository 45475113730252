import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'; // Importa BrowserRouter
import AppRouter from './Router/AppRouter';

function App() {
  return (
    <Router> {/* Envuelve tu aplicación con el componente Router */}
      <AppRouter />
    </Router>
  );
}

export default App;
