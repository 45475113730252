import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    TextField,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    TableContainer,
    InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { url } from "./Config";
import CustomModal from "./CustomModal";

function StocksProductos() {
    useEffect(() => {
        document.title = "Productos";
    }, []);
    
    const [userData, setUserData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [modalMessage, setModalMessage] = useState({ type: "", text: "" });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ide_eje, set_ide_eje] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const parsedUserData = JSON.parse(storedUserData);
            if (parsedUserData.length > 0) {
                set_ide_eje(parsedUserData[0].ide_eje);
            }
        }
    }, []);

    useEffect(() => {
        if (ide_eje) {
            ConsultaProductos();
        }
    }, [ide_eje]);

    const ConsultaProductos = async () => {
        try {
            const requestData = { ide_eje: ide_eje };
            const response = await fetch(`${url}/post_vw_bienes_servicios`, {
                method: "POST",
                referrerPolicy: "unsafe-url",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            if (data.success && data.data) {
                setUserData(data.data);
                setFilteredData(data.data); // Inicializa filteredData con todos los datos
            } else {
                setUserData([]);
                setFilteredData([]);
                setModalMessage({ type: "warning", text: "No se encontraron datos." });
                setIsModalOpen(true);
            }
        } catch (error) {
            setUserData([]);
            setFilteredData([]);
            setModalMessage({ type: "error", text: "Error fetching user data: " + error.message });
            setIsModalOpen(true);
        }
    };

    const handleSearchChange = (event) => {
        const upperCaseTerm = event.target.value.toUpperCase();
        setSearchTerm(upperCaseTerm);
        if (upperCaseTerm === '') {
            setFilteredData(userData);
        } else {
            const filtered = userData.filter((item) =>
                item.des_pro.toUpperCase().includes(upperCaseTerm)
            );
            setFilteredData(filtered);
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const FontSize = "12px";

    return (
        <>
            <Typography fullWidth variant="h4" textAlign="left" color="textPrimary" mb={1}>
                Lista de Productos
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={5}>
                    <TextField
                        id="cad_lar"
                        type="text"
                        fullWidth
                        value={searchTerm}
                        onChange={handleSearchChange}
                        label="Buscar Producto"
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            style: { textTransform: 'uppercase' }
                        }}
                    />
                </Grid>
            </Grid>
            <TableContainer component={Paper} sx={{ maxHeight: "70vh", marginTop: 2, overflowX: "auto" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ textAlign: 'left', width: '0%', fontSize: FontSize }}>#</TableCell>
                            <TableCell sx={{ textAlign: 'center', width: '2%', fontSize: FontSize }}>Código</TableCell>
                            <TableCell sx={{ textAlign: 'center', width: '7%', fontSize: FontSize }}>Unidad</TableCell>
                            <TableCell sx={{ textAlign: 'center', width: '20%', fontSize: FontSize }}>Descripción Producto</TableCell>
                            <TableCell sx={{ textAlign: 'center', width: '5%', fontSize: FontSize }}>Valor Venta</TableCell>
                            <TableCell sx={{ textAlign: 'center', width: '5%', fontSize: FontSize }}>Stock</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ fontSize: FontSize }}>{index + 1}</TableCell>
                                <TableCell sx={{ fontSize: FontSize }}>{row.cod_pro}</TableCell>
                                <TableCell sx={{ fontSize: FontSize }}>{row.des_u_m}</TableCell>
                                <TableCell sx={{ fontSize: FontSize }}>{row.des_pro}</TableCell>
                                <TableCell sx={{ fontSize: FontSize, textAlign: 'right', minWidth: 100 }}>{Number(row.val_unt).toFixed(2)}</TableCell>
                                <TableCell sx={{ fontSize: FontSize, textAlign: 'right', minWidth: 100 }}>{Number(row.sto_cks).toFixed(2)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <CustomModal isOpen={isModalOpen} toggle={toggleModal} message={modalMessage} />
        </>
    );
}

export default StocksProductos;
