import React, { useState, useEffect } from 'react';
import {
  Typography, Button, Grid, Input, FormLabel, TableContainer, Table, TableHead,
  TableRow, TableCell, TableBody, TableFooter, Paper,CircularProgress,Backdrop
} from "@mui/material";
import { url } from "./Config";
import CustomModal from "./CustomModal";

function AlquilerHab() {
    useEffect(() => {
        document.title = "Alq. Habitación";
      }, []);
    const [userData, setUserData] = useState([]);
    const [modalMessage, setModalMessage] = useState({ type: "", text: "" });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fechaDesde, setFechaDesde] = useState("");
    const [fechaHasta, setFechaHasta] = useState("");
    const [ide_eje, set_ide_eje] = useState('');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      if (parsedUserData.length > 0) {
        set_ide_eje(parsedUserData[0].ide_eje);
      }
    }
    const today = new Date();
    const localToday = today.toLocaleDateString('en-CA');
    setFechaDesde(localToday);
    setFechaHasta(localToday);
  }, []);

  useEffect(() => {
    if (ide_eje) {
      ConsultaAlquiler(fechaDesde, fechaHasta);
    }
  }, [ide_eje]);

  const ConsultaAlquiler = async (desde, hasta) => {
    setLoading(true);
    try {
      const requestData = {ide_eje: ide_eje,desde: desde,hasta: hasta,};
      const response = await fetch(`${url}/post_vw_alquiler_det_ingresos`, {
        method: "POST",
        referrerPolicy: "unsafe-url" ,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      //console.log(data);
      if (data.success && data.data) {
        setUserData(data.data);
      } else {
        setUserData([]);
        setModalMessage({ type: "warning", text: "No se encontraron datos." });
        setIsModalOpen(true);
      }
    } catch (error) {
      setUserData([]);
      setModalMessage({ type: "error", text: "Error fetching user data: " + error.message });
      setIsModalOpen(true);
    }finally {
      setLoading(false);
    } 
  };
  const DescargaExcel = async (desde, hasta) => {
    setLoading(true);
    try {
      const DataExcel = {ide_eje: ide_eje,desde: desde,hasta: hasta,};
      const ResponseXsl = await fetch(`${url}/post_vw_registro_huesped`, {
        method: "POST",
        referrerPolicy: "unsafe-url",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(DataExcel),
      });
      if (!ResponseXsl.ok) {
        setModalMessage({ type: "error", text: "Error al obtener el archivo Excel" });
        setIsModalOpen(true);
      }

      const blob = await ResponseXsl.blob();
      const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'Registro_de_Huespedes.xlsx');
      document.body.appendChild(link);
      link.click();

    } catch (error) {
      setModalMessage({ type: "error", text: "Error: " + error.message });
      setIsModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleConsulta = () => {
    ConsultaAlquiler(fechaDesde, fechaHasta);
  };

  const ConsultaResponseXsl = () => {
    DescargaExcel(fechaDesde, fechaHasta);
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const total_pre_hab = userData.reduce((acc, item) => acc + Number(item.pre_hab), 0);
  const total_pre_dia = userData.reduce((acc, item) => acc + Number(item.pre_dia), 0);
  const FontSize = "12px";

  return (
    <>
     <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress  />
      </Backdrop>
      <Typography variant="h4" textAlign="left" color="textPrimary" mb={1}>
        Ingresos de Alquiler de Habitación
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <FormLabel sx={{ fontWeight: "bold" }}>Desde</FormLabel>
          <Input id="fechaDesde" type="date" value={fechaDesde} onChange={(e) => setFechaDesde(e.target.value)} />
        </Grid>
        <Grid item>
          <FormLabel sx={{ fontWeight: "bold" }}>Hasta</FormLabel>
          <Input id="fechaHasta" type="date" value={fechaHasta} onChange={(e) => setFechaHasta(e.target.value)} />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={handleConsulta}>
            Consultar
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="success" onClick={ConsultaResponseXsl}>
            Descargar datos en Excel
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ maxHeight: "70vh", marginTop: 2, overflowX: "auto" }}>
        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 1500 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: 'center', width: '3%', fontSize: FontSize }}>#</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '3%', fontSize: FontSize }}>N° Piso</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '3%', fontSize: FontSize }}>N° Hab.</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '15%', fontSize: FontSize }}>Tip. Habitación</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '7%', fontSize: FontSize }}>DNI</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '20%', fontSize: FontSize }}>Huesped</TableCell>            
              <TableCell sx={{ textAlign: 'center', width: '13%', fontSize: FontSize }}>Fecha Alq.</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '5%', fontSize: FontSize }}>Precio Hab.</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '5%', fontSize: FontSize }}>Precio Alq.</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '30%', fontSize: FontSize }}>Ubicación</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '30%', fontSize: FontSize }}>Observación</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontSize: FontSize }}>{index + 1}</TableCell>
                <TableCell sx={{ fontSize: FontSize }}>{row.nro_pis}</TableCell>
                <TableCell sx={{ fontSize: FontSize }}>{row.nro_hab}</TableCell>
                <TableCell sx={{ fontSize: FontSize }}>{row.des_t_h}</TableCell>
                <TableCell sx={{ fontSize: FontSize }}>{row.doc_hue}</TableCell>
                <TableCell sx={{ fontSize: FontSize }}>{row.nom_hue}</TableCell>       
                <TableCell sx={{ fontSize: FontSize }}>{row.fch_alq_txt}</TableCell>
                <TableCell sx={{ fontSize: FontSize, textAlign: 'right', minWidth: 100 }}>{Number(row.pre_hab).toFixed(3)}</TableCell>
                <TableCell sx={{ fontSize: FontSize, textAlign: 'right', minWidth: 100 }}>{Number(row.pre_dia).toFixed(3)}</TableCell>
                <TableCell sx={{ fontSize: FontSize }}>{row.ubi_dis}</TableCell>
                <TableCell sx={{ fontSize: FontSize }}>{row.obs_alq}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell sx={{ fontSize: FontSize, fontFamily: 'Arial', fontWeight: "bold" }}>Total</TableCell>
              <TableCell colSpan={6} />
              <TableCell sx={{ fontSize: FontSize, fontFamily: 'Arial', fontWeight: "bold", textAlign: 'right' }}>{total_pre_hab.toFixed(3)}</TableCell>
              <TableCell sx={{ fontSize: FontSize, fontFamily: 'Arial', fontWeight: "bold", textAlign: 'right' }}>{total_pre_dia.toFixed(3)}</TableCell>
              <TableCell colSpan={2} />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <CustomModal isOpen={isModalOpen} toggle={toggleModal} message={modalMessage} />
    </>
  );
}

export default AlquilerHab;
