import React, { useState, useEffect } from "react";
import {
  Typography, Button,Grid,Input,FormLabel,TableContainer,Table,TableHead,TableRow,
  TableCell,TableBody,TableFooter,Paper,CircularProgress,Backdrop
} from "@mui/material";
import { url } from "./Config";
import CustomModal from "./CustomModal";

function Comprobantes() {
  useEffect(() => {
    document.title = "Comprobantes";
  }, []);

  const [userData, setUserData] = useState([]);
  const [modalMessage, setModalMessage] = useState({ type: "", text: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fechaDesde, setFechaDesde] = useState("");
  const [fechaHasta, setFechaHasta] = useState("");
  const [ide_eje, set_ide_eje] = useState('');
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      if (parsedUserData.length > 0) {
        set_ide_eje(parsedUserData[0].ide_eje);
      }
    }
    const today = new Date();
    const localToday = today.toLocaleDateString('en-CA');
    setFechaDesde(localToday);
    setFechaHasta(localToday);
  }, []);

  useEffect(() => {
    if (ide_eje) {
      fetchUserData(fechaDesde, fechaHasta);
    }
  }, [ide_eje]);

  const fetchUserData = async (desde, hasta) => {
    setLoading(true);
    try {
      const requestData = {ide_eje: ide_eje,desde: desde,hasta: hasta,};
      const response = await fetch(`${url}/post_vw_recibo_cab`, {
        method: "POST",
        referrerPolicy: "unsafe-url",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      if (data.success && data.data) {
        setUserData(data.data);
      } else {
        setUserData([]);
        setModalMessage({ type: "warning", text: "No se encontraron datos." });
        setIsModalOpen(true);
      }
    } catch (error) {
      setUserData([]);
      setModalMessage({ type: "error", text: "Error: " + error.message });
      setIsModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const DescargaExcel = async (desde, hasta) => {
    setLoading(true);
    try {
      setDownloading(true);
      const DataExcel = {ide_eje: ide_eje,desde: desde,hasta: hasta,};
      const ResponseXsl = await fetch(`${url}/post_vw_recibo_cab_rpt`, {
        method: "POST",
        referrerPolicy: "unsafe-url",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(DataExcel),
      });
      if (!ResponseXsl.ok) {
        setModalMessage({ type: "error", text: "Error al obtener el archivo Excel" });
        setIsModalOpen(true);
      }

      const blob = await ResponseXsl.blob();
      const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'Ingresos_por_tipo_de_comprobante.xlsx');
      document.body.appendChild(link);
      link.click();

    } catch (error) {
      setModalMessage({ type: "error", text: "Error: " + error.message });
      setIsModalOpen(true);
    } finally {
      setDownloading(false);
      setLoading(false);
    }
  }

  const handleConsulta = () => {
    fetchUserData(fechaDesde, fechaHasta);
  };

  const ConsultaResponseXsl = () => {
    DescargaExcel(fechaDesde, fechaHasta);
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const totalImporte = userData.reduce((acc, item) => acc + Number(item.imp_tot), 0);

  const FontSize = "12px";
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress  />
      </Backdrop>

      <Typography variant="h4" textAlign="left" color="textPrimary" mb={1}>
        Lista de Comprobantes Emitidos
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <FormLabel sx={{ fontWeight: "bold" }}>Desde</FormLabel>
          <Input id="fechaDesde" type="date" value={fechaDesde} onChange={(e) => setFechaDesde(e.target.value)} />
        </Grid>
        <Grid item>
          <FormLabel sx={{ fontWeight: "bold" }}>Hasta</FormLabel>
          <Input id="fechaHasta" type="date" value={fechaHasta} onChange={(e) => setFechaHasta(e.target.value)} />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={handleConsulta}>
            Consultar
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="success" onClick={ConsultaResponseXsl} disabled={downloading}>
            Descargar datos en Excel
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ maxHeight: "70vh", marginTop: 2, overflowX: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: 'center', width: '3%', fontSize: FontSize }}>#</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '7%', fontSize: FontSize }}>Tip. Doc.</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '15%', fontSize: FontSize }}>N° de Doc.</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '7%', fontSize: FontSize }}>Emisión</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '10%', fontSize: FontSize }}>DNI/RUC</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '30%', fontSize: FontSize }}>Cliente</TableCell>
              <TableCell sx={{ textAlign: 'right', width: '7%', fontSize: FontSize, minWidth: 100 }}>Imp. Total</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '5%', fontSize: FontSize }}>Anulado</TableCell>
              <TableCell sx={{ textAlign: 'center', width: '5%', fontSize: FontSize }}>SUNAT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontSize: FontSize }}>{index + 1}</TableCell>
                <TableCell sx={{ fontSize: FontSize }}>{row.des_doc}</TableCell>
                <TableCell sx={{ fontSize: FontSize }}>{row.cad_nro_doc}</TableCell>
                <TableCell sx={{ fontSize: FontSize }}>{row.fch_emi}</TableCell>
                <TableCell sx={{ fontSize: FontSize }}>{row.doc_prv}</TableCell>
                <TableCell sx={{ fontSize: FontSize }}>{row.nom_prv}</TableCell>
                <TableCell sx={{ fontSize: FontSize, textAlign: 'right', minWidth: 100 }}>{Number(row.imp_tot).toFixed(3)}</TableCell>
                <TableCell sx={{ fontSize: FontSize, color: row.flg_anu === 1 ? "red" : "inherit" }}>
                  {row.flg_anu === 1 ? "Anulado" : ""}
                </TableCell>
                <TableCell sx={{ fontSize: FontSize, color: row.flg_sun === 1 ? "green" : "inherit" }}>
                  {row.flg_sun === 1 ? "Transmitido" : ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell sx={{ fontSize: FontSize, fontFamily: 'Arial', fontWeight: "bold" }}>Total</TableCell>
              <TableCell colSpan={5} />
              <TableCell sx={{ fontSize: FontSize, fontFamily: 'Arial', fontWeight: "bold", textAlign: 'right' }}>{totalImporte.toFixed(3)}</TableCell>
              <TableCell colSpan={2} />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <CustomModal open={isModalOpen} onClose={toggleModal} message={modalMessage} />
    </>
  );
}

export default Comprobantes;
